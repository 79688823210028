import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  selector: "app-prefix-vol",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-vol.component.html",
  styleUrl: "./prefix-vol.component.less",
})
export class PrefixVolComponent extends PrefixTextComponent {
  public constructor() {
    super();
    this.post = false;
    this.hide = true;
    this.characters = ALLOWED_CHARACTERS["vol"];
  }
}
