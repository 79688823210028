import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextareaComponent } from "../../templates/template-textarea/template-textarea.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-bs4",
  imports: [TemplateTextareaComponent],
  templateUrl: "./prefix-bs4.component.html",
  styleUrl: "./prefix-bs4.component.less",
})
export class PrefixBs4Component extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 7000;
    this.characters = ALLOWED_CHARACTERS["bs4"];
  }
}
