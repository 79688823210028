import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplatePasswordComponent } from "../../templates/template-password/template-password.component";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

@Component({
  selector: "app-prefix-wa3",
  imports: [TemplatePasswordComponent],
  templateUrl: "./prefix-wa3.component.html",
  styleUrl: "./prefix-wa3.component.less",
})
export class PrefixWa3Component extends PrefixComponent<string> {
  public minlength: MinLength;
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.required = true;
    this.post = true;
    this.minlength = 8;
    this.maxlength = 12;
    this.characters = ALLOWED_CHARACTERS["wa3"];
  }
}
