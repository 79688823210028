import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormRecord, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { GridValue } from "src/app/components/default/form/form.interface";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { Direction } from "../template-checkbox/template-checkbox.component";
import { TemplateDateComponent } from "../template-date/template-date.component";
import { TemplateTextareaComponent } from "../template-textarea/template-textarea.component";

@Component({
  selector: "app-template-grid",
  imports: [ReactiveFormsModule, TranslateModule, CommonModule, MatCheckboxModule, TemplateDateComponent, TemplateTextareaComponent, IconReplacePipe],
  templateUrl: "./template-grid.component.html",
  styleUrls: ["./template-grid.component.less"],
})
export class TemplateGridComponent<CONTROL = GridValue[]> extends DefaultComponent implements PrefixTemplate<CONTROL, GridValue[]>, OnInit {
  @Input({ required: true })
  public control: FormControl<CONTROL> | null;

  @Input()
  public value: GridValue[] | null;

  @Input()
  public label: string | null;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public direction: Direction;

  @Input()
  public date: boolean;

  @Input()
  public text: boolean;

  public group: FormRecord<GridFormGroup>;

  public checked: boolean;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.label = null;
    this.required = false;
    this.disabled = false;
    this.date = false;
    this.text = false;
    this.checked = false;

    this.direction = "vertical";
    this.group = new FormRecord({});
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
      this.group = new FormRecord(this.createForm());
      this.checked = this.hasChecked();

      this.addSubscription(
        this.group.valueChanges.subscribe((v) => {
          this.checked = this.hasChecked();
          if (this.control) {
            this.control.markAsDirty();
            this.control.patchValue(
              <CONTROL>Object.entries(v).map(([key, value]) => ({
                ...this.value?.find((option) => option.id === key),
                ...value,
              })),
            );
          }
        }),
      );

      if (this.disabled) {
        this.group.disable();
      }
    } else {
      throw new Error("Undefined control");
    }
  }

  public getControl(id: string, controlName: string): FormControl {
    return this.group.controls[id].controls[controlName];
  }

  private addValidators(control: FormControl<CONTROL>): void {
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }

  /**
   * Create FormGroup based on options
   * @returns
   */
  private createForm(): Record<string, GridFormGroup> {
    return (<GridValue[]>this.value).reduce((total, option) => {
      return {
        ...total,
        [option.id]: new FormGroup({
          dateFrom: new FormControl<string | null>(option.dateFrom),
          dateTo: new FormControl<string | null>(option.dateTo),
          text: new FormControl<string | null>(option.text),
          checked: new FormControl<boolean>(option.checked),
        }),
      };
    }, {});
  }

  private hasChecked(): boolean {
    const value = this.group.value;
    return Object.values(value).some((v) => v && v.checked);
  }
}

export type GridFormGroup = FormGroup<{
  dateFrom: FormControl<string | null>;
  dateTo: FormControl<string | null>;
  text: FormControl<string | null>;
  checked: FormControl<boolean>;
}>;
