import { AfterViewInit, Component } from "@angular/core";
import { DialogTitle } from "../../template/dialog-template.component";
import { DialogComponent } from "../dialog.component";

export interface ThumbnailEnlargeDialogData {
  canvas: HTMLCanvasElement;
  title: DialogTitle;
}

@Component({
  selector: "app-thumbnail-enlarge-dialog",
  imports: [],
  templateUrl: "./thumbnail-enlarge-dialog.component.html",
  styleUrl: "./thumbnail-enlarge-dialog.component.less",
})
export class ThumbnailEnlargeDialogComponent extends DialogComponent<ThumbnailEnlargeDialogData> implements AfterViewInit {
  public source: string | null;

  public constructor() {
    super();
    this.source = null;
  }

  public ngAfterViewInit(): void {
    if (this.data) {
      this.source = this.data.canvas.toDataURL();
    }
  }
}
