import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-cdp",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-cdp.component.html",
  styleUrl: "./prefix-cdp.component.less",
})
export class PrefixCdpComponent extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 12;
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["cdp"];
  }
}
