import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { DecimalSeperator } from "../../types/DecimalSeperator";
import { Max } from "../../types/Max";
import { Min } from "../../types/Min";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

@Component({
  selector: "app-template-number",
  imports: [ReactiveFormsModule, TemplateHTMLComponent],
  templateUrl: "./template-number.component.html",
  styleUrl: "./template-number.component.less",
})
export class TemplateNumberComponent extends DefaultComponent implements PrefixTemplate<string, string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public min: Min;

  @Input()
  public max: Max;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public commas: number;

  @Input()
  public characters: string | null;

  @Input()
  public decimalSeperator: DecimalSeperator;

  @Input()
  public label: string | null;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.min = null;
    this.max = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.commas = 0;
    this.characters = null;
    this.decimalSeperator = ",";
    this.label = null;
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
      this.parseValue(control.value);
      this.addSubscription(control.valueChanges.subscribe((value) => this.parseValue(value)));
    } else {
      throw new Error("Undefined control");
    }
  }

  /**
   * Parse value to correct view
   * @param input
   */
  public parseValue(value: string | null): void {
    if (value != null && !isNaN(parseFloat(value))) {
      const isParsed = new RegExp(`^[0-9]+${this.commas ? `\\${this.decimalSeperator}[0-9]{${this.commas}}` : ""}$`);
      if (isParsed.test(value)) return;

      const isNegative = value.startsWith("-"); // Check if starts with -
      const splitted = value
        .replace(/[^0-9,.]/, "") // Replace all characters that are not numeric, comma or dot
        .replace(",", this.decimalSeperator) // replace commas with dot
        .replace(".", this.decimalSeperator) // replace commas with dot
        .split(this.decimalSeperator) // split on dot
        .map((value) => value.split("")); // split to single character strings

      // Map to numbers
      const preDot = splitted[0].map((v) => Number(v));
      // Remove zeros at the start
      while (preDot[0] === 0 && preDot.length > 1) {
        preDot.shift();
      }
      let newValue = `${preDot.join("")}`;

      // Limit the decimals to set amount and round it
      if (this.commas > 0) {
        if (splitted[1]?.length) {
          const postDot = splitted[1].map((v) => Number(v));
          const trimmed = postDot.slice(0, this.commas);

          if (trimmed.length !== postDot.length) {
            // From right to left, round the cut off decimals according the the value to their right until there is 1 number left
            const roundUp = postDot.slice(this.commas).reduceRight((prev, curr) => (prev >= 5 ? curr + 1 : curr)) >= 5 ? true : false;
            // If remaining decimal >= 5, increment the last value of the shown decimals
            if (roundUp) trimmed[trimmed.length - 1] = trimmed[trimmed.length - 1] + 1;
          }
          // Append to the values pre-comma
          newValue += `${this.decimalSeperator}${trimmed.join("").padEnd(this.commas, "0")}`;
        } else {
          newValue += `${this.decimalSeperator}${"".padEnd(this.commas, "0")}`;
        }
      }

      if (isNegative) newValue = `-${newValue}`; // add minus if is negative

      this.control?.setValue(newValue);
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const input = event.data?.split("") ?? [];
    const invalid = !!input.filter((str) => this.characters && !this.characters.includes(str)).length;
    if (invalid) event.preventDefault();
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.min) control.addValidators([PrefixValidator.min(this.min)]);
    if (this.max) control.addValidators([PrefixValidator.max(this.max)]);
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }
}
