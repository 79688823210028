import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { LinkData, TemplateLinkComponent } from "../../templates/template-link/template-link.component";

@Component({
  selector: "app-prefix-ln2",
  imports: [TemplateLinkComponent],
  templateUrl: "./prefix-ln2.component.html",
  styleUrl: "./prefix-ln2.component.less",
})
export class PrefixLn2Component extends PrefixComponent<LinkData[]> {
  @Input()
  public href: LinkData[];
  @Input()
  public routes: LinkData[];

  public constructor() {
    super();
    this.href = [];
    this.routes = [];
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["ln2"];
  }
}
