import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-te2",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-te2.component.html",
  styleUrl: "./prefix-te2.component.less",
})
export class PrefixTe2Component extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 24;
    this.characters = ALLOWED_CHARACTERS["te2"];
    this.required = true;
  }
}
