import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTimeComponent } from "../../templates/template-time/template-time.component";

@Component({
  selector: "app-prefix-ti2",
  imports: [TemplateTimeComponent],
  templateUrl: "./prefix-ti2.component.html",
  styleUrl: "./prefix-ti2.component.less",
})
export class PrefixTi2Component extends PrefixComponent<string> {
  public constructor() {
    super();
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["ti2"];
  }
}
