import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import SignaturePad from "signature_pad";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";

@Component({
  selector: "app-template-signature",
  imports: [MatIconModule, MatButtonModule, TranslateModule],
  templateUrl: "./template-signature.component.html",
  styleUrl: "./template-signature.component.less",
})
export class TemplateSignatureComponent extends DefaultComponent implements PrefixTemplate<string>, AfterViewInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public label: string | null;

  @ViewChild("signature")
  public canvas: ElementRef<HTMLCanvasElement> | null;

  public signaturePad: SignaturePad | null;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.label = null;
    this.canvas = null;
    this.signaturePad = null;
  }

  public ngAfterViewInit(): void {
    if (this.canvas) {
      const pad = new SignaturePad(this.canvas.nativeElement);
      pad.addEventListener("endStroke", () => this.save(pad));
      this.signaturePad = pad;
    }
  }

  /**
   * Save canvas value
   * @param pad
   */
  public save(pad: SignaturePad): void {
    if (this.control) {
      this.control.setValue(pad.toDataURL());
    } else {
      throw new Error("Undefined control");
    }
  }

  /**
   * Clear the canvas
   */
  public clear(): void {
    if (this.signaturePad && this.control) {
      this.signaturePad.clear();
      this.control.setValue(null);
    } else {
      throw new Error("signature or control undefined.");
    }
  }
}
