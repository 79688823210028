import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-cd8",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-cd8.component.html",
  styleUrl: "./prefix-cd8.component.less",
})
export class PrefixCd8Component extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super({
      defaultValue: "0000000",
      updateOn: "blur",
    });
    this.maxlength = 12;
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["cd8"];
  }

  public override onControlValue(value: string | null): void {
    if (value) {
      if (/^[0-9]+$/.test(value)) value = value.padStart(7, "0");
      super.onControlValue(value);
      this.updateControl(value, false);
    }
  }
}
