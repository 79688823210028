import { Component, Input, OnInit } from "@angular/core";
import { PrefixComponent } from "../../prefix.component";
import { Coordinate, MapOptions, Marker, TemplateMapComponent } from "../../templates/template-map/template-map.component";

@Component({
  selector: "app-prefix-geo",
  imports: [TemplateMapComponent],
  templateUrl: "./prefix-geo.component.html",
  styleUrl: "./prefix-geo.component.less",
})
export class PrefixGeoComponent extends PrefixComponent<never> implements OnInit {
  @Input()
  public markers: Marker[];

  @Input()
  public location: Coordinate | null;

  public mapOptions: google.maps.MapOptions;
  public markerOptions: google.maps.marker.AdvancedMarkerElementOptions;

  public minlength: number;
  public maxlength: number;

  public options: MapOptions;

  public constructor() {
    super();
    this.post = false;
    this.mapOptions = {};
    this.markerOptions = {};
    this.minlength = 1;
    this.maxlength = 1;

    this.markers = [];

    this.options = {};
    this.location = null;
  }
}
