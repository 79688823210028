import { Component, OnInit } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { PREVIEW_INPUT_TYPE, TemplatePreviewComponent } from "../../templates/template-preview/template-preview.component";

@Component({
  selector: "app-prefix-im2",
  imports: [TemplatePreviewComponent],
  templateUrl: "./prefix-im2.component.html",
  styleUrl: "./prefix-im2.component.less",
})
export class PrefixIm2Component extends PrefixTextComponent implements OnInit {
  public type: PREVIEW_INPUT_TYPE;

  public constructor() {
    super({ updateOn: "blur" });
    this.type = PREVIEW_INPUT_TYPE.TEXTAREA;
    this.characters = ALLOWED_CHARACTERS["im2"];
  }

  public setImage(context: TemplatePreviewComponent): void {
    const canvas = context.getCanvas();
    context.updateCanvas((resolve, reject) => {
      const img = new Image();
      img.src = this.control.value || "";
      img.crossOrigin = "Anonymous";
      img.onload = (): void => {
        resolve("success");
        canvas.height = img.height;
        canvas.width = img.width;

        console.error("img dimensions => ", {
          width: img.width,
          height: img.height,
        });
        canvas.getContext("2d")?.drawImage(img, 0, 0);
      };
      img.onerror = (): void => {
        reject("unable to load image");
      };
    });
  }
}
