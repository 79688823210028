import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DefaultComponent } from "src/app/default.component";

import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";

export interface LinkData {
  icon?: string;
  link: string;
  value: string;
}

@Component({
  imports: [RouterModule, IconReplacePipe],
  selector: "app-template-link",
  templateUrl: "./template-link.component.html",
  styleUrl: "./template-link.component.less",
})
export class TemplateLinkComponent<CONTROL, VALUE = CONTROL> extends DefaultComponent implements PrefixTemplate<CONTROL, VALUE>, OnInit {
  @Input({ required: true })
  public control: FormControl<CONTROL> | null;

  @Input()
  public value: VALUE | null;

  @Input()
  public disabled: boolean;

  @Input()
  public href: LinkData[];

  @Input()
  public routes: LinkData[];

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.disabled = false;

    this.href = [];
    this.routes = [];
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      if (this.routes) this.routes = this.routes.map((data) => this.changeValueLink(data));
      if (this.href) this.href = this.href.map((data) => this.changeValueLink(data));
    } else {
      throw new Error("Undefined control");
    }
  }

  public createIconElement(iconClassList: string): string {
    return `<i class="${iconClassList}"></i>`;
  }

  private changeValueLink(data: LinkData): LinkData {
    if (data.value === "geendisplay") data.value = "<i class='fas fa-pencil-alt'></i>";
    return data;
  }
}
