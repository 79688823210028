import { AfterViewInit, Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { PrefixValidator } from "../../PrefixValidator";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  selector: "app-prefix-ban",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-ban.component.html",
  styleUrl: "./prefix-ban.component.less",
})
export class PrefixBanComponent extends PrefixTextComponent implements AfterViewInit {
  public constructor() {
    super();
    this.counter = false;
    this.characters = ALLOWED_CHARACTERS["ban"];
    this.required = false;
  }

  public ngAfterViewInit(): void {
    this.control.addValidators(PrefixValidator.iban());
    this.control.updateValueAndValidity();
  }
}
