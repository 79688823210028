import { AfterViewInit, Component, Input } from "@angular/core";
import { PrefixValidator } from "src/app/components/global/prefixes/PrefixValidator";
import { PrefixBedComponent } from "src/app/components/global/prefixes/impl/prefix-bed/prefix-bed.component";
import { PrefixComponent } from "src/app/components/global/prefixes/prefix.component";
import { TemplateNumberComponent } from "src/app/components/global/prefixes/templates/template-number/template-number.component";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";

@Component({
  selector: "app-question-type-upp",
  imports: [PrefixBedComponent, TemplateNumberComponent],
  templateUrl: "./question-type-upp.component.html",
  styleUrl: "./question-type-upp.component.less",
})
export class QuestionTypeUppComponent extends PrefixComponent<string> implements AfterViewInit {
  @Input()
  public unitPrice: number | null;

  public price: string | null;

  public constructor() {
    super();
    this.characters = ALLOWED_CHARACTERS["gt2"];

    this.unitPrice = null;
    this.price = null;

    this.subscriptions.add(
      this.control.valueChanges.subscribe(() => {
        this.calculatePrice();
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (this.required) this.control.addValidators([PrefixValidator.required()]);
    this.calculatePrice();
    this.control.updateValueAndValidity();
  }

  public calculatePrice(): void {
    let unitCount = 0;
    if (this.value) {
      const intVal = Number.parseInt(this.value);
      if (!Number.isNaN(intVal) && intVal > -1) {
        unitCount = intVal;
      }
    }
    this.price = (unitCount * (this.unitPrice || 0)).toString();
  }
}
