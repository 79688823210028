import { Component, Input, OnInit } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNotificationComponent } from "../../templates/template-notification/template-notification.component";

@Component({
  selector: "app-prefix-toe",
  imports: [TemplateNotificationComponent],
  templateUrl: "./prefix-toe.component.html",
  styleUrl: "./prefix-toe.component.less",
})
export class PrefixToeComponent extends PrefixComponent<string> implements OnInit {
  @Input()
  public severity: number;

  public constructor() {
    super();
    this.post = false;
    this.severity = 1;
    this.characters = ALLOWED_CHARACTERS["toe"];
  }

  public ngOnInit(): void {
    this.hide = !this.value;
    super.ngOnInit();
  }
}
