import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-pr2",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-pr2.component.html",
  styleUrl: "./prefix-pr2.component.less",
})
export class PrefixPr2Component extends PrefixComponent<string> {
  public max: Max;
  public maxlength: MaxLength;

  public constructor() {
    super({
      defaultValue: "0",
      updateOn: "blur",
    });
    this.max = 100;
    this.maxlength = 3;
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["pr2"];
  }
}
