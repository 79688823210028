import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

@Component({
  selector: "app-template-textarea",
  imports: [ReactiveFormsModule, TemplateHTMLComponent],
  templateUrl: "./template-textarea.component.html",
  styleUrl: "./template-textarea.component.less",
})
export class TemplateTextareaComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit, AfterViewInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input({ required: true })
  public value: string | null;

  @Input()
  public minlength: MinLength;

  @Input()
  public maxlength: MaxLength;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @ViewChild("area")
  public area: ElementRef<HTMLTextAreaElement> | null;

  @Input()
  public resize: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public counter: boolean;

  @Input()
  public label: string | null;

  public focused: boolean;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.minlength = null;
    this.maxlength = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.area = null;
    this.resize = true;
    this.characters = null;
    this.counter = true;
    this.label = null;
    this.focused = false;
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  public ngAfterViewInit(): void {
    if (!this.disabled) {
      const area = this.area;
      if (area) {
        area.nativeElement.addEventListener("input", (e) => this.onInputChange(<HTMLTextAreaElement>e.target));
        this.onInputChange(area.nativeElement);
      } else {
        throw new Error("Could not find textarea element.");
      }
    }
  }

  /**
   * Triggered on every input change
   * @param event
   */
  public onInputChange(target: HTMLTextAreaElement): void {
    target.style.height = "auto";
    const height = target.scrollHeight + (target.offsetHeight - target.clientHeight);
    if (height) target.style.height = `${height}px`;
  }

  public onBeforeInput(event: InputEvent): void {
    const input = event.data?.split("") ?? [];
    const invalid = !!input.filter((str) => this.characters && !this.characters.includes(str)).length;
    if (invalid) event.preventDefault();
  }

  /**
   * Set counter boolean
   */
  public switchFocus(): void {
    this.focused = !this.focused;
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.minlength) control.addValidators([PrefixValidator.minlength(this.minlength)]);
    if (this.maxlength) control.addValidators([PrefixValidator.maxlength(this.maxlength)]);
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }
}
