import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateUploadComponent } from "../../templates/template-upload/template-upload.component";

@Component({
  selector: "app-prefix-fil",
  imports: [TemplateUploadComponent],
  templateUrl: "./prefix-fil.component.html",
  styleUrl: "./prefix-fil.component.less",
})
export class PrefixFilComponent extends PrefixComponent<File> {
  public constructor() {
    super();
    this.characters = ALLOWED_CHARACTERS["fil"];
  }
}
