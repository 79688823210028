import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from "@angular/core";
import * as QRCode from "qrcode";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";

@Component({
  selector: "app-prefix-qrc",
  imports: [],
  templateUrl: "./prefix-qrc.component.html",
  styleUrl: "./prefix-qrc.component.less",
})
export class PrefixQrcComponent extends PrefixComponent<string> implements OnInit {
  @ViewChild("canvas", { static: true })
  public canvas: ElementRef<HTMLCanvasElement> | null;
  public source: string | null;
  public title: string | null;

  private cd: ChangeDetectorRef;

  public constructor() {
    super();
    this.cd = inject(ChangeDetectorRef);
    this.canvas = null;
    this.source = null;
    this.title = null;
    this.characters = ALLOWED_CHARACTERS["qrc"];
    this.post = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Force update control's value
   * @param value
   */
  public override updateControl(value: string, emit: boolean = true): void {
    super.updateControl(value, emit);
    this.updateQR(value);
  }

  public async updateQR(value: string): Promise<void> {
    if (value && this.canvas) {
      this.source = await QRCode.toDataURL(this.canvas.nativeElement, value, { width: 128, margin: 0, errorCorrectionLevel: "H" });
      this.title = value;
      this.cd.markForCheck();
    }
  }

  protected override setInitialControlValue(): void {
    return;
  }
}
