import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-tel",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-tel.component.html",
  styleUrl: "./prefix-tel.component.less",
})
export class PrefixTelComponent extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 24;
    this.characters = ALLOWED_CHARACTERS["tel"];
  }
}
