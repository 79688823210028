import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { PrefixComponent } from "../../prefix.component";
import { Coordinate, MapOptions, Polygon, TemplateMapComponent } from "../../templates/template-map/template-map.component";

@Component({
  selector: "app-prefix-gep",
  imports: [TemplateMapComponent],
  templateUrl: "./prefix-gep.component.html",
  styleUrl: "./prefix-gep.component.less",
})
export class PrefixGepComponent extends PrefixComponent<never> implements AfterViewInit {
  @Input()
  public polygon: Polygon | null;

  @Input()
  public location: Coordinate | null;

  @ViewChild(TemplateMapComponent)
  public map: TemplateMapComponent | null;

  public mapOptions: google.maps.MapOptions;

  public minlength: number;
  public maxlength: number;

  public options: MapOptions;

  public constructor() {
    super();
    this.post = false;
    this.mapOptions = {};
    this.polygon = null;
    this.minlength = 1;
    this.maxlength = 1;
    this.map = null;

    this.options = {};
    this.location = null;
  }

  public ngAfterViewInit(): void {
    if (!this.map) return;
    const { map, mapPolygon } = this.map;

    if (map && mapPolygon) {
      const bounds = new google.maps.LatLngBounds();
      mapPolygon.getPaths().forEach((p) => {
        p.forEach((element) => bounds.extend(element));
      });
      map.fitBounds(bounds);
    }
  }
}
