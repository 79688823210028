import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";


export type CheckboxDirection = "horizontal" | "vertical";

export interface RadioOption {
  label: string;
  value: string;
}

@Component({
  selector: "app-template-radio",
  imports: [CommonModule, MatRadioModule, ReactiveFormsModule, IconReplacePipe],
  templateUrl: "./template-radio.component.html",
  styleUrl: "./template-radio.component.less",
})
export class TemplateRadioComponent extends DefaultComponent implements PrefixTemplate<string, string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public label: string | null;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public options: RadioOption[];

  @Input()
  public direction: CheckboxDirection;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.label = null;
    this.required = false;
    this.disabled = false;

    this.options = [];
    this.direction = "horizontal";
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      if (control.value === null) control.patchValue(this.options[0].value);
    } else {
      throw new Error("Undefined control");
    }
  }
}
