import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";
import { Regex } from "../../types/Regex";

@Component({
  selector: "app-prefix-uu4",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-uu4.component.html",
  styleUrl: "./prefix-uu4.component.less",
})
export class PrefixUu4Component extends PrefixComponent<string> {
  public max: Max;
  public regex: Regex;
  public commas: number;

  public constructor() {
    super({
      defaultValue: "0",
      updateOn: "blur",
    });
    this.max = 16;
    this.regex = "UU4";
    this.commas = 2;
    this.characters = ALLOWED_CHARACTERS["uu4"];
  }
}
