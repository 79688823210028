import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  selector: "app-prefix-per",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-per.component.html",
  styleUrl: "./prefix-per.component.less",
})
export class PrefixPerComponent extends PrefixTextComponent {
  public constructor() {
    super();
    this.post = false;
    this.hide = true;
    this.characters = ALLOWED_CHARACTERS["per"];
  }
}
