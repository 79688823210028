import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { distinctUntilChanged, filter } from "rxjs";
import { FormField } from "src/app/components/default/form/form-field";
import { DefaultComponent } from "src/app/default.component";
import { PrefixContainerDirective } from "src/directives/prefix-container.directive";
import { PrefixComponent } from "../../prefix.component";
import { FORM_PREFIX_DATA } from "./form-prefix-data";

@Component({
  imports: [PrefixContainerDirective],
  selector: "app-form-prefix[field]",
  templateUrl: "./form-prefix.component.html",
  styleUrl: "./form-prefix.component.less",
})
export class FormPrefixComponent extends DefaultComponent implements OnInit {
  @Input()
  public field: FormField | null;

  @Output()
  public onchange: EventEmitter<FormField>;

  @Output()
  public dirty: EventEmitter<void>;

  @ViewChild(PrefixContainerDirective, { static: true })
  private prefixContainer: PrefixContainerDirective | null;

  @ViewChild("noPrefixComponent", { static: true })
  public noPrefixComponent: TemplateRef<unknown> | null;

  public constructor() {
    super();
    this.field = null;

    this.prefixContainer = null;
    this.noPrefixComponent = null;
    this.onchange = new EventEmitter();
    this.dirty = new EventEmitter();
  }

  public ngOnInit(): void {
    try {
      if (this.field) this.render(this.field);
    } catch (e) {
      console.error(e);
    }
  }

  public render(field: FormField): void {
    if (this.prefixContainer) {
      const prefix = FORM_PREFIX_DATA[field.template];

      if (prefix) {
        const container = this.prefixContainer.viewContainerRef;
        const component = container.createComponent(prefix.class);
        const instance = <PrefixComponent<string>>component.instance;

        const label = field.name.substring(3).split(":")[0];

        /** Sanitize name */
        component.setInput("label", label);

        if (field.readonly) {
          component.setInput("disabled", true);
        } else {
          component.setInput("disabled", false);
        }

        for (const [name, property] of Object.entries(prefix.inputs)) {
          component.setInput(name, field.extras?.[property] ?? property);
        }

        this.addSubscription(
          field.value.subscribe((value) => {
            component.setInput("value", value);
          }),
          field.value
            .pipe(
              distinctUntilChanged(),
              filter(() => instance.control.dirty),
            )
            .subscribe(() => {
              this.dirty.emit();
            }),
          instance.valuechanged.subscribe((value) => {
            field.value.next(value);
            this.onchange.emit(field);
          }),
          instance.hidechanged.subscribe((value) => {
            field.hidden = field.hidden ? field.hidden : value;
            this.onchange.emit(field);
          }),
          instance.requiredchanged.subscribe((value) => (field.required = value)),
          instance.postchanged.subscribe((value) => (field.postable = value)),
          instance.threatchanged.subscribe((value) => {
            field.threat.next(value);
            this.onchange.emit(field);
          }),
          instance.touchedchanged.subscribe((value) => {
            field.touched = value;
          }),
        );
      } else {
        console.error(`Invalid prefix ${field.template}, it may not have been implemented yet.`);
        if (this.noPrefixComponent) this.prefixContainer.viewContainerRef.createEmbeddedView(this.noPrefixComponent);
      }
    } else {
      throw new Error("PrefixContainer undefined.");
    }
  }
}
