import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";

@Component({
  selector: "app-prefix-xem",
  imports: [],
  templateUrl: "./prefix-xem.component.html",
  styleUrl: "./prefix-xem.component.less",
})
export class PrefixXemComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["xem"];
  }
}
