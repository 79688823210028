import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";
import { Regex } from "../../types/Regex";

@Component({
  selector: "app-prefix-pos",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-pos.component.html",
  styleUrl: "./prefix-pos.component.less",
})
export class PrefixPosComponent extends PrefixTextComponent {
  public maxlength: MaxLength;
  public regex: Regex;

  public constructor() {
    super();
    this.maxlength = 6;
    this.regex = "POSTALCODE";
    this.characters = ALLOWED_CHARACTERS["pos"];
  }

  public override onControlValue(value: string | null): void {
    if (value) {
      const newValue = value.toUpperCase();
      super.onControlValue(newValue);
      this.updateControl(newValue, false);
    }
  }
}
