import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

@Component({
  selector: "app-prefix-wa2",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-wa2.component.html",
  styleUrl: "./prefix-wa2.component.less",
})
export class PrefixWa2Component extends PrefixComponent<string> {
  public minlength: MinLength;
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.post = true;
    this.minlength = 8;
    this.maxlength = 12;
    this.characters = ALLOWED_CHARACTERS["wa2"];
  }
}
