import { AsyncPipe, NgClass } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, distinctUntilChanged } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { DialogService, DialogTemplate } from "../../../dialog/dialog.service";
import {
  ThumbnailEnlargeDialogComponent,
  ThumbnailEnlargeDialogData,
} from "../../../dialog/impl/thumbnail-enlarge-dialog/thumbnail-enlarge-dialog.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { ThreatLevel } from "../../prefix.component";
import { TemplateHTMLComponent } from "../template-html/template-html.component";
import { TemplateTextComponent } from "../template-text/template-text.component";
import { TemplateTextareaComponent } from "../template-textarea/template-textarea.component";

export enum PREVIEW_INPUT_TYPE {
  TEXT,
  TEXTAREA,
}

@Component({
  selector: "app-template-preview",
  imports: [ReactiveFormsModule, AsyncPipe, NgClass, TemplateTextComponent, TemplateTextareaComponent, TranslateModule, TemplateHTMLComponent],
  templateUrl: "./template-preview.component.html",
  styleUrl: "./template-preview.component.less",
})
export class TemplatePreviewComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit, AfterViewInit {
  public dialog: DialogService;

  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public edit: boolean;

  @Input()
  public type: PREVIEW_INPUT_TYPE;

  @Input()
  public loadMs: number;

  @Input()
  public disabled: boolean;

  @ViewChild("thumbnail")
  public thumbnail: ElementRef<HTMLCanvasElement> | null;

  @ViewChild("canvas")
  public canvas: ElementRef<HTMLCanvasElement> | null;

  @ViewChild("error")
  public content: ElementRef<HTMLDivElement> | null;

  @Output()
  public preview: EventEmitter<TemplatePreviewComponent>;

  private updater: NodeJS.Timeout | null;

  public valid: BehaviorSubject<boolean>;
  public empty: boolean;

  public constructor() {
    super();
    this.dialog = inject(DialogService);
    this.control = null;
    this.value = null;
    this.edit = true;
    this.type = PREVIEW_INPUT_TYPE.TEXT;
    this.thumbnail = null;
    this.canvas = null;
    this.updater = null;
    this.loadMs = 500;
    this.content = null;
    this.valid = new BehaviorSubject(true);
    this.empty = true;
    this.preview = new EventEmitter();
    this.disabled = false;
  }

  public async ngOnInit(): Promise<void> {
    const control = this.control;
    if (control) {
      control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        if (this.canvas) this.preview.emit(this);
      });
    }
  }

  public ngAfterViewInit(): void {
    if (this.canvas) this.preview.emit(this);
  }

  public getThumbnail(): HTMLCanvasElement {
    if (this.thumbnail) {
      return this.thumbnail.nativeElement;
    } else {
      throw new Error("Could not find thumbnail.");
    }
  }

  public getCanvas(): HTMLCanvasElement {
    if (this.canvas) {
      return this.canvas.nativeElement;
    } else {
      throw new Error("Could not find canvas.");
    }
  }

  public clearThumbnail(): void {
    const canvas = this.getThumbnail();
    const ctx = canvas.getContext("2d");
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    this.valid.next(true);
  }

  public async updateCanvas(method: ConstructorParameters<PromiseConstructor>[0], timeoutMs: number = 2500): Promise<void> {
    try {
      if (this.control) this.addValidator(this.control);
      if (this.control && this.control.value && this.control.value.length > 0) {
        this.tryClearTimeout(this.updater);
        this.updater = setTimeout(() => this.valid.next(false), timeoutMs);

        await new Promise(method)
          .then(() => this.valid.next(true))
          .catch(() => this.valid.next(false))
          .finally(() => {
            this.control?.updateValueAndValidity();
            this.tryClearTimeout(this.updater);
            this.updateThumbnail()
              .then(() => (this.empty = this.isEmpty(this.getThumbnail())))
              .catch(() => {
                // console.warn("Did not update thumbnail => ", err)
              });
          });
      } else {
        throw new Error("Could not update canvas, control value is not set.");
      }
    } catch (err) {
      this.clearThumbnail();
      this.empty = this.isEmpty(this.getThumbnail());
    }
  }

  public async updateThumbnail(): Promise<void> {
    return new Promise((resolve) => {
      const canvas = this.getCanvas();
      const thumbnail = this.getThumbnail();
      const img = new Image();
      img.src = canvas.toDataURL();
      img.onload = (): void => {
        thumbnail.getContext("2d")?.drawImage(img, 0, 0);
        resolve();
      };
    });
  }

  public enlarge(): void {
    this.dialog.open<ThumbnailEnlargeDialogData>(
      ThumbnailEnlargeDialogComponent,
      {
        canvas: this.getCanvas(),
        title: {
          label: "DIALOG.PREVIEW.TITLE",
        },
      },
      DialogTemplate.MODAL,
    );
  }

  private isEmpty(canvas: HTMLCanvasElement): boolean {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      const data = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;
      return !data.some((x) => x > 0);
    } else {
      return true;
    }
  }

  private tryClearTimeout(timeout: NodeJS.Timeout | null): void {
    if (timeout) clearTimeout(timeout);
  }

  private addValidator(control: FormControl<string | null>): void {
    control.addValidators(
      PrefixValidator.bool(this.valid, {
        message: this.content?.nativeElement.innerHTML,
        invalidThreatLevel: ThreatLevel.WARNING,
      }),
    );
    control.updateValueAndValidity();
  }
}
