import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { Direction } from "../../templates/template-checkbox/template-checkbox.component";
import { RadioOption, TemplateRadioComponent } from "../../templates/template-radio/template-radio.component";

@Component({
  selector: "app-prefix-idu",
  imports: [TemplateRadioComponent],
  templateUrl: "./prefix-idu.component.html",
  styleUrl: "./prefix-idu.component.less",
})
export class PrefixIduComponent extends PrefixComponent<string> {
  @Input()
  public options: RadioOption[];
  public direction: Direction;

  public constructor() {
    super();
    this.options = [];
    this.direction = "vertical";
    this.characters = ALLOWED_CHARACTERS["idu"];
  }
}
