import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, merge } from "rxjs";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateDateComponent } from "../../templates/template-date/template-date.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { TemplateTimeComponent } from "../../templates/template-time/template-time.component";

@Component({
  selector: "app-prefix-di3",
  imports: [TemplateDateComponent, TemplateTimeComponent, TemplateNumberComponent],
  templateUrl: "./prefix-di3.component.html",
  styleUrl: "./prefix-di3.component.less",
})
export class PrefixDi3Component extends PrefixComponent<Date> {
  public dateControl: FormControl<string | null>;
  public timeControl: FormControl<string | null>;
  public secondControl: FormControl<string | null>;
  public msControl: FormControl<string | null>;

  public constructor() {
    super();
    this.characters = ALLOWED_CHARACTERS["di3"];

    this.dateControl = new FormControl();
    this.timeControl = new FormControl();
    this.secondControl = new FormControl("", { updateOn: "blur" });
    this.msControl = new FormControl("", { updateOn: "blur" });

    this.addSubscription(
      this.control.valueChanges.pipe(distinctUntilChanged()).subscribe((date) => this.onChange(date)),
      merge(this.dateControl.valueChanges, this.timeControl.valueChanges, this.secondControl.valueChanges, this.msControl.valueChanges)
        .pipe(debounceTime(1000))
        .subscribe(() => this.onFieldChange()),
    );
  }

  private onChange(date: Date | null): void {
    if (date != null) {
      const datestring = date.toISOString();
      try {
        const iso = datestring.split("T");
        const time = iso[1].split(":");
        const seconds = time[2].split(".");

        this.dateControl.patchValue(iso[0], { emitEvent: false });
        this.timeControl.patchValue(`${time[0]}:${time[1]}`, { emitEvent: false });
        this.secondControl.patchValue(seconds[0], { emitEvent: false });
        this.msControl.patchValue(seconds[1].substring(0, seconds[1].length - 1), { emitEvent: false });
      } catch (err) {
        console.error("Error in onchange di3! => ", err);
      }
    }
  }

  private onFieldChange(): void {
    const date = this.dateControl.value;
    const time = this.timeControl.value;
    const seconds = this.secondControl.value;
    const milliSeconds = this.msControl.value;

    if (date != null && time != null && seconds != null && milliSeconds != null) {
      this.control.patchValue(new Date(`${date}T${time}:${seconds}.${milliSeconds}Z`));
    } else {
      console.error("Something was null in onFieldChange");
    }
  }
}
