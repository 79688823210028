import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-cdx",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-cdx.component.html",
  styleUrl: "./prefix-cdx.component.less",
})
export class PrefixCdxComponent extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 12;
    this.characters = ALLOWED_CHARACTERS["cdx"];
  }
}
