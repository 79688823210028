import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateDatetimeComponent } from "../../templates/template-datetime/template-datetime.component";

@Component({
  selector: "app-prefix-ti3",
  imports: [TemplateDatetimeComponent],
  templateUrl: "./prefix-ti3.component.html",
  styleUrl: "./prefix-ti3.component.less",
})
export class PrefixTi3Component extends PrefixComponent<string> {
  public constructor() {
    super();
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["ti3"];
  }
}
