import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent, TextAlign } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-om6",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-om6.component.html",
  styleUrl: "./prefix-om6.component.less",
})
export class PrefixOm6Component extends PrefixTextComponent {
  public maxlength: MaxLength;
  public align: TextAlign;

  public constructor() {
    super();
    this.maxlength = 30;
    this.align = "end";
    this.characters = ALLOWED_CHARACTERS["om6"];
  }
}
